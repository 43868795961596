<template>
  <div>
    <DataPrivacy></DataPrivacy>
    <DataPrivacy2></DataPrivacy2>
  </div>
</template>

<script>
  const DataPrivacy = () => import('../components/DataPrivacy.vue')
  const DataPrivacy2 = () => import('../components/DataPrivacy2.vue')

  export default {
    components: {
      DataPrivacy,
      DataPrivacy2
    }
  }
</script>